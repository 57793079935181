import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useLoginState } from '@xing-com/hub';

export type FooterConfig = {
  title: string;
  items: {
    title: string;
    href: string;
    skipTracking?: boolean;
  }[];
}[];

export const useFooterConfig = (): FooterConfig => {
  const isLoggedIn = useLoginState() === 'LOGGED_IN';
  const { locale, formatMessage } = useIntl();

  return useMemo(() => {
    const footerConfig: FooterConfig = [
      {
        title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_COMPANY' }),
        items: [
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_NEWWORKSE',
            }),
            href:
              locale === 'de'
                ? 'https://www.new-work.se/de'
                : 'https://www.new-work.se/en',
          },
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_CAREER' }),
            href:
              locale === 'de'
                ? 'https://www.new-work.se/de/karriere/'
                : 'https://www.new-work.se/en/career/',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_INVESTOR_RELATIONS',
            }),
            href:
              locale === 'de'
                ? 'https://www.new-work.se/de/investor-relations/'
                : 'https://www.new-work.se/en/investor-relations/',
          },
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_PRESS' }),
            href:
              locale === 'de'
                ? 'https://www.new-work.se/de/newsroom/'
                : 'https://www.new-work.se/en/newsroom/',
          },
          ...(locale === 'de' && !isLoggedIn
            ? [
                {
                  title: formatMessage({
                    id: 'VERTICAL_NAVIGATION_FOOTER_NEW_WORK_EXPERIENCE',
                  }),
                  href: 'https://nwx.new-work.se',
                },
              ]
            : []),
        ],
      },
      {
        title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_RESOURCES' }),
        items: [
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_HELP' }),
            href:
              locale === 'de'
                ? 'https://faq.xing.com/de'
                : 'https://faq.xing.com/en',
          },
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_DEVBLOG' }),
            href: 'https://tech.new-work.se/',
          },
          ...(isLoggedIn
            ? []
            : [
                {
                  title: formatMessage({
                    id: 'VERTICAL_NAVIGATION_FOOTER_APP_GALLERY',
                  }),
                  href:
                    locale === 'de'
                      ? 'https://mobile.xing.com/de/'
                      : 'https://mobile.xing.com/en/',
                },
              ]),
          {
            title: 'Baller League',
            href: 'https://ballerleague.xing.com/',
            skipTracking: true,
          },
        ],
      },
      {
        title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_MEMBERSHIPS' }),
        items: [
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_PREMIUM_MEMBERSHIP',
            }),
            href: 'https://www.xing.com/purchase/premium?reagent=uplt_98',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_PROJOBS_MEMBERSHIP',
            }),
            href: 'https://www.xing.com/purchase/projobs?reagent=uplt_205',
          },
        ],
      },
    ];

    if (isLoggedIn) {
      footerConfig.push({
        title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_APPS' }),
        items: [
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_IPHONE_IPAD',
            }),
            href: 'https://itunes.apple.com/de/app/xing/id297625850?mt=8&ct=footer_Marketing&at=10lRnD',
            skipTracking: true,
          },
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_ANDROID' }),
            href: 'https://play.google.com/store/apps/details?id=com.xing.android&referrer=utm_source%3DXINGWeb%26utm_medium%3DFooter',
            skipTracking: true,
          },
        ],
      });
    }

    if (!isLoggedIn) {
      footerConfig.push({
        title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_YOUR_XING' }),
        items: [
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_PEOPLE_DIRECTORY',
            }),
            href: 'https://www.xing.com/people/',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_JOBS_DIRECTORY',
            }),
            href: 'https://www.xing.com/jobs/directory/a',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_XING_NEWS',
            }),
            href: 'https://www.xing.com/news',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_COMPANIES',
            }),
            href: 'https://www.xing.com/companies',
          },
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_COACHES' }),
            href: 'https://coaches.xing.com/',
          },
        ],
      });
      footerConfig.push({
        title: 'Services',
        items: [
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_TALENT_MANAGER',
            }),
            href:
              locale === 'de'
                ? 'https://recruiting.xing.com/de/'
                : 'https://recruiting.xing.com/en/',
          },
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_XAS' }),
            href: 'https://www.xing.com/xam/personal/ads',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_ADVERTISE',
            }),
            href:
              locale === 'de'
                ? 'https://werben.xing.com'
                : 'https://advertising.xing.com',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_LEBENSLAUF',
            }),
            href: 'https://lebenslauf.com/',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_ANSCHREIBEN',
            }),
            href: 'https://anschreiben.com/',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_BEWERBUNG',
            }),
            href: 'https://bewerbung.com/',
          },
          {
            title: formatMessage({ id: 'VERTICAL_NAVIGATION_FOOTER_KUNUNU' }),
            href: 'https://www.kununu.com/',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_PRAKTIKUM_GUIDE',
            }),
            href: 'https://praktikum-guide.com/',
          },
          {
            title: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_CAREER_GUIDE',
            }),
            href: 'https://www.xing.com/karriere-ratgeber',
          },
        ],
      });
    }

    return footerConfig;
  }, [isLoggedIn, locale, formatMessage]);
};
