import type { Experiment, Variant } from '@xing-com/hub';

import { useExperiments } from './splitkit-module';

export const FALLBACK_VARIANT: Variant = 'A';

export function getVariantOrFallback(
  experiment: Experiment | undefined,
  fallback: Variant = FALLBACK_VARIANT
): Variant {
  if (!experiment) return fallback;

  const isCaseSupported = experiment.supportedVariants.includes(
    experiment.variant
  );

  // A is always the default and fallback if case is not supported
  if (!isCaseSupported) return fallback;

  return experiment.variant;
}

export function useExperiment(
  name: string,
  fallback: Variant = FALLBACK_VARIANT
): Variant {
  const { data: experiments } = useExperiments();

  return getVariantOrFallback(experiments?.[name], fallback);
}

export function useExperimentTrackingData(): {
  PropExperiment?: string;
  PropExperimentInfo?: string;
} {
  const { data: experiments } = useExperiments();

  if (!experiments || Object.values(experiments).length === 0) {
    return {};
  }

  const runningExperiments: Experiment[] = Object.values(experiments);
  const propExperiment: string[] = [];
  const propExperimentInfo: string[] = [];

  for (const experiment of runningExperiments) {
    const displayed =
      experiment.status === 200
        ? getVariantOrFallback(experiment)
        : FALLBACK_VARIANT;

    const received = experiment.status === 200 ? experiment.variant : '0';
    const shortExperimentName = experiment.name.replace('ABACUS', 'A');

    // data means
    // - name of the experiment
    // - which variant was displayed (A if something went wrong)
    // - which variant was reported by the backend (0 if something went wrong)
    propExperiment.push(`${shortExperimentName}|${displayed}|${received}`);

    // TODO: Replace with proper Error tracking, PropExperimentInfo is therefore not needed

    // data means
    // - name of the experiment
    // - status: 200 = OK, 404 = experiment not found, 500 = error during request
    // - user type: U = logged in, V = logged out
    const experimentType = experiment.experimentType === 'user' ? 'U' : 'V';

    const experimentInfo = [
      shortExperimentName,
      experiment.status,
      experimentType,
    ].join('|');

    propExperimentInfo.push(experimentInfo);
  }

  return {
    PropExperiment: propExperiment.join(','),
    PropExperimentInfo: propExperimentInfo.join(','),
  };
}
