import type * as React from 'react';

import type { ButtonProps } from '@xing-com/button';

import * as Styled from './content-banner.styles';
const { variants, displays } = Styled;

export type ContentBannerVariant = keyof typeof variants;
export type ContentBannerDisplay = keyof typeof displays;

export type ContentBannerProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Properties for the close button */
  closeButtonProps?: ButtonProps;
  /** Display type of the banner */
  display?: ContentBannerDisplay;
  /** @callback */
  handleOnClose?: () => void;
  /** Remove the padding */
  noPadding?: boolean;
  /** @callback */
  onExited?: () => void;
  /** Show the banner */
  show?: boolean;
  /** Show the close button */
  showCloseButton?: boolean;
  /** The variant of the banner */
  variant?: ContentBannerVariant;
} & React.HTMLAttributes<HTMLDivElement>;

export type ContentBannerAutoHideProps = {
  /** @callback */
  handleOnClose?: () => void;
  /** Show the banner */
  show?: boolean;
  /** Timeout until the banner closes */
  timeout?: number;
} & ContentBannerProps;
