import type React from 'react';
import type { PropsWithChildren } from 'react';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { getMainHost } from '@xing-com/crate-login-utils';
import { useHost } from '@xing-com/crate-xinglet';
import { Link } from '@xing-com/link';

import { Styled } from './background-layout.styles';
import energizer from './images/energizer.jpeg';
import hiddenJobs from './images/hidden-jobs.png';

export const BackgroundLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { getHostname } = useHost();
  const mainHost = getMainHost(getHostname());
  const { getSearchParameter } = useSearchParameter();
  const hasHiddenJobsParam =
    getSearchParameter('dest_url')?.includes('hidden-jobs');

  return (
    <>
      <Styled.TopBar hideLoginButton hideSignupButton />

      <Styled.LoginContainer>
        <Styled.ColumnWithBackground>
          <Link href={mainHost}>
            <Styled.XingLogo variant="mono" size={96} />
          </Link>
          <Styled.Energizer
            src={hasHiddenJobsParam ? hiddenJobs : energizer}
            alt=""
            loading="lazy"
          />
        </Styled.ColumnWithBackground>

        <Styled.Column>{children}</Styled.Column>
      </Styled.LoginContainer>
    </>
  );
};
