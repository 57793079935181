/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconJobsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconJobsFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 14v2h2v-2h8v7H3v-7zm1-11a5 5 0 0 1 4.9 4H21v5H3V7h4.1A5 5 0 0 1 12 3m0 2a3 3 0 0 0-2.83 2h5.66A3 3 0 0 0 12 5"
      clipRule="evenodd"
    />
  </svg>
);
export default IconJobsFilled;
