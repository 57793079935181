import type * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { useLoginState } from '@xing-com/hub';
import { Link } from '@xing-com/link';
import { scale005, scale050, xdlColorTextSecondary } from '@xing-com/tokens';
import { addParams } from '@xing-com/xing-tracking';

const reOpenConsent = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
): void => {
  e.preventDefault();
  // @ts-expect-error UC_UI comes from the usercentrics consent script
  UC_UI.showSecondLayer();
};

const LinkToTracking: React.FC = () => {
  return (
    <a href="https://www.xing.com/" onClick={reOpenConsent}>
      Tracking
    </a>
  );
};

const legalFooterNavItemTracking = {
  sc_o: 'navigation_footer',
} as const;

const LinkToHref: React.FC<React.PropsWithChildren<{ href: string }>> = ({
  children,
  href,
}) => {
  const link = addParams(href, legalFooterNavItemTracking);
  return <Link to={link}>{children}</Link>;
};

const StyledItem = styled.li`
  & a {
    display: block;
    padding-block: calc(${scale050} + ${scale005});
    color: ${xdlColorTextSecondary};
  }
`;

export const LegalFooterNavItems: React.FC = () => {
  const { locale } = useIntl();
  const isLoggedIn = useLoginState() === 'LOGGED_IN';

  const privacyHref =
    locale === 'de'
      ? 'https://privacy.xing.com/de'
      : 'https://privacy.xing.com/en';

  const policyHref =
    locale === 'de'
      ? 'https://privacy.xing.com/de/datenschutzerklaerung'
      : 'https://privacy.xing.com/en/privacy-policy';

  return (
    <>
      <StyledItem>
        <LinkToHref href="https://www.xing.com/legalnotice">
          <FormattedMessage id="VERTICAL_NAVIGATION_FOOTER_IMPRINT" />
        </LinkToHref>
      </StyledItem>
      <StyledItem>
        <LinkToHref href="https://www.xing.com/terms">
          <FormattedMessage id="VERTICAL_NAVIGATION_FOOTER_TANDC" />
        </LinkToHref>
      </StyledItem>
      <StyledItem>
        <LinkToHref href={privacyHref}>
          <FormattedMessage id="VERTICAL_NAVIGATION_FOOTER_PRIVACY" />
        </LinkToHref>
      </StyledItem>
      <StyledItem>
        <LinkToHref href={policyHref}>
          <FormattedMessage id="VERTICAL_NAVIGATION_FOOTER_PRIVACY_POLICY" />
        </LinkToHref>
      </StyledItem>
      {isLoggedIn ? (
        <StyledItem>
          <LinkToHref href="https://www.xing.com/settings/purchases">
            <FormattedMessage id="VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_ADMINISTRATION" />
          </LinkToHref>
        </StyledItem>
      ) : (
        <StyledItem>
          <LinkToHref href="https://www.xing.com/support/contact/memberships">
            <FormattedMessage id="VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_CANCELLATION" />
          </LinkToHref>
        </StyledItem>
      )}
      <StyledItem>
        <LinkToTracking />
      </StyledItem>
    </>
  );
};
