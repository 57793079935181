import type * as React from 'react';
import styled from 'styled-components';

import {
  mediaWideNavless,
  mediaConfined,
  mediaSmallOrTiny,
} from '@xing-com/layout-tokens';
import {
  spaceM,
  spaceXL,
  xdlColorTextSecondary,
  spaceS,
} from '@xing-com/tokens';

import { LegalFooterNavItems } from './legal-footer-nav-items';

const FooterBox = styled.div`
  display: block;
  color: ${xdlColorTextSecondary};

  @media ${mediaConfined} {
    padding-left: 0;
    padding-right: 0;
  }

  @media ${mediaWideNavless} {
    padding: 0 ${spaceM};
  }
`;

const StyledFooterNavLinksList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${spaceXL};
  list-style: none;
  margin: -3px 0;
  padding: 0;

  @media ${mediaSmallOrTiny} {
    column-gap: ${spaceS};
  }
`;

export const LegalFooterStandalone: React.FC = () => {
  return (
    <FooterBox>
      <nav>
        <StyledFooterNavLinksList data-qa="frame-footer-legal-nav">
          <LegalFooterNavItems />
          <li> | &copy; New Work SE</li>
        </StyledFooterNavLinksList>
      </nav>
    </FooterBox>
  );
};
