import type { FC } from 'react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';

import type { Button } from '@xing-com/button';
import { useMetaConfig } from '@xing-com/hub';
import { MetaRenderer, type ConfigForResolvers } from '@xing-com/meta-renderer';
import { commonMetaRendererResolvers } from '@xing-com/platform-layout-common-meta-renderer-resolvers';
import { addParams } from '@xing-com/xing-tracking';

import { ActivityCenterButton } from './activity-center-button';
import { BusinessSolutionsMenu } from './business-solutions-menu';
import { BsMenuItem } from './business-solutions-menu-item';
import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledLogo,
  StyledLogoWrapper,
  StyledNav,
  StyledPlacebo,
  StyledNavItemContainer,
  StyledButtonSmallWhenTopBar,
} from './header-logged-out.styles';
import { NavItemHeaderLoggedOut } from './nav-item-header-logged-out';
import { getFullLoginLink } from './utils/get-full-login-link';

const ButtonSmallWhenTopBar: FC<
  Omit<React.ComponentProps<typeof Button>, 'size'>
> = (props) => {
  return (
    <>
      <StyledButtonSmallWhenTopBar {...props} size="small" />
      <StyledButtonSmallWhenTopBar {...props} size="medium" />
    </>
  );
};

type HeaderLoggedOutProps = {
  className?: string;
  hideLoginButton?: boolean;
  hideSignupButton?: boolean;
  homeLink?: string;
  loginLink?: string;
  /** @deprecated In order to avoid breaking changes, we keep the signupLink prop */
  signupLink?: string;
};

const HeaderLoggedOutResolvers = {
  NavItemHeaderLoggedOut,
  BusinessSolutionsMenu,
  BsMenuItem,
  ActivityCenterButton,
};

export const resolvers = {
  ...commonMetaRendererResolvers,
  ...HeaderLoggedOutResolvers,
};

export type HeaderLoggedOutConfig = ConfigForResolvers<typeof resolvers>;

const HeaderLoggedOut: React.FC<HeaderLoggedOutProps> = ({
  className,
  hideLoginButton,
  hideSignupButton,
  homeLink,
  loginLink = getFullLoginLink(),
}) => {
  const { formatMessage } = useIntl();

  const { data } = useMetaConfig();
  const [loginButtonTo, setLoginButtonTo] = useState<string | undefined>(
    undefined
  );

  const trackingUrl = addParams(loginLink, {
    sc_o: 'navigation_sign_up_button',
  });

  useEffect(() => {
    setLoginButtonTo(trackingUrl);
  }, [trackingUrl]);

  return (
    <StyledPlacebo className={className}>
      <StyledHeaderWrapper>
        <StyledHeader>
          <StyledLogoWrapper>
            <StyledLogo
              butterfly
              homeLink={homeLink}
              dataQa="logoMobile"
              size={28}
            />
            <StyledLogo
              variant="duo"
              size={96}
              homeLink={homeLink}
              dataQa="logo"
            />
          </StyledLogoWrapper>

          <StyledNav data-qa="loggedOut-nav">
            {!hideLoginButton && (
              <React.Fragment>
                <StyledNavItemContainer>
                  {data?.navigation?.headerLoggedOut ? (
                    <MetaRenderer
                      config={data?.navigation?.headerLoggedOut}
                      resolvers={resolvers}
                    />
                  ) : null}
                </StyledNavItemContainer>
                {!hideSignupButton && !hideLoginButton ? (
                  <ButtonSmallWhenTopBar
                    data-testid="styled-Login-Button"
                    to={loginButtonTo}
                    variant="primary"
                    href={loginLink}
                  >
                    {formatMessage({
                      id: 'MAINNAV_LOGIN',
                    })}
                  </ButtonSmallWhenTopBar>
                ) : null}
              </React.Fragment>
            )}
          </StyledNav>
        </StyledHeader>
      </StyledHeaderWrapper>
    </StyledPlacebo>
  );
};

export { HeaderLoggedOut };
