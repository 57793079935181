import type * as React from 'react';
import styled from 'styled-components';

import { DiscoverBadge } from '@xing-com/badge';
import { useExperiment } from '@xing-com/hub';
import { IconSearch } from '@xing-com/icons';
import { spaceXS, scale070, scale110 } from '@xing-com/tokens';

const StyledIconWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  position: relative;
`;

const StyledDiscoverBadge = styled(DiscoverBadge)`
  position: absolute;
  top: calc(${spaceXS} * -1);
  right: calc(${spaceXS} * -1);
  height: ${scale070};
  width: ${scale070};
`;

type IconWithDiscoverBadgeProps = {
  icon?: React.ElementType<{ className?: string }>;
  title?: string;
  className?: string;
};

const UnstyledIconWithDiscoverBadge: React.FC<IconWithDiscoverBadgeProps> = ({
  icon: Icon = IconSearch,
  title,
  className,
}) => {
  const isAskXingExperimentEnabled = useExperiment('ABACUS-488') === 'B';
  const isAskXingViewed =
    global.localStorage?.getItem('isAskXingViewed') === 'true';
  const showDiscoverBadge = isAskXingExperimentEnabled && !isAskXingViewed;

  return showDiscoverBadge ? (
    <StyledIconWrapper>
      <Icon className={className} aria-label={title} />
      <StyledDiscoverBadge />
    </StyledIconWrapper>
  ) : (
    <Icon className={className} aria-label={title} />
  );
};

const IconWithDiscoverBadge = styled(UnstyledIconWithDiscoverBadge)`
  flex: 0 0 ${scale110};
  height: ${scale110};
  width: ${scale110};
`;

export { IconWithDiscoverBadge };
