import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconCross } from '@xing-com/icons';

import * as Styled from './content-banner.styles';
import type { ContentBannerProps } from './content-banner.types';

type ContentBannerPrimitiveProps = Omit<
  ContentBannerProps,
  'onExited' | 'show'
>;

export const ContentBannerPrimitive = React.forwardRef<
  HTMLDivElement,
  ContentBannerPrimitiveProps
>(
  (
    {
      children,
      closeButtonProps,
      display = 'fixed',
      handleOnClose,
      noPadding,
      showCloseButton = true,
      variant = 'default',
      ...props
    },
    forwaredRef
  ): JSX.Element => {
    const intl = useIntl();

    return (
      <Styled.ContentBanner
        $closingIndicator={!!handleOnClose}
        $display={display}
        $noPadding={noPadding}
        $variant={variant}
        data-xds="ContentBanner"
        ref={forwaredRef}
        role="region"
        {...props}
      >
        {children}
        {handleOnClose && showCloseButton ? (
          <Styled.Close
            $display={display}
            aria-label={intl.formatMessage({
              id: 'XDS_CLOSE_BUTTON_A11Y',
              defaultMessage: 'Close',
            })}
            data-qa="close"
            data-testid="close"
            icon={IconCross}
            onClick={handleOnClose}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              if (e.key === 'Escape') {
                handleOnClose?.();
              }
            }}
            size="medium"
            {...closeButtonProps}
          />
        ) : null}
      </Styled.ContentBanner>
    );
  }
);

ContentBannerPrimitive.displayName = 'ContentBanner (Primitive)';
