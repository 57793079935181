/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconPremium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconPremium"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.551 3 1.167 8.987l10.83 12.541.003.002L22.833 8.986 17.445 3zM9.03 5H7.443L4.744 8h3.242zm1.066 3h9.159l-2.7-3H11.14zm9.219 2-5.536 6.41L16.01 10zm-8.063 7.605L13.9 10H4.685z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPremium;
