/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconBusinessSolutions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconBusinessSolutions"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2 17V3h20v14h-8v2h2v2H8v-2h2v-2zM20.5 4.5h-17v11h17zM19 6v5h-1.5V8.542l-5.508 5.052-2.65-2.742-4.098 3.351-.95-1.16 5.167-4.225 2.594 2.683L16.416 7.5H14V6z"
    />
  </svg>
);
export default IconBusinessSolutions;
