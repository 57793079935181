/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconSettings"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m14.895 2 .296 2.662a8 8 0 0 1 1.567.906l2.455-1.075 2.895 5.014-2.159 1.588a8 8 0 0 1 0 1.81l2.159 1.588-2.895 5.014-2.455-1.075a8 8 0 0 1-1.567.906L14.895 22h-5.79l-.296-2.662a8 8 0 0 1-1.567-.906l-2.455 1.075-2.895-5.014 2.159-1.588a8 8 0 0 1 0-1.81L1.892 9.507l2.895-5.014 2.455 1.075a8 8 0 0 1 1.567-.906L9.105 2zm-1.79 2h-2.21l-.235 2.115-.6.205a6 6 0 0 0-2.006 1.16l-.478.418-1.952-.855L4.52 8.957l1.714 1.261-.121.622a6 6 0 0 0 0 2.32l.121.622-1.714 1.261 1.105 1.914 1.952-.855.478.418a6 6 0 0 0 2.005 1.16l.6.205.236 2.115h2.21l.235-2.115.6-.206a6 6 0 0 0 2.006-1.16l.478-.417 1.952.855 1.104-1.914-1.714-1.261.122-.622a6 6 0 0 0 0-2.32l-.122-.622 1.715-1.261-1.105-1.914-1.952.855-.478-.418a6 6 0 0 0-2.006-1.16l-.6-.205zM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8m0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"
    />
  </svg>
);
export default IconSettings;
