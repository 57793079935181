import * as React from 'react';
import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceS } from '@xing-com/tokens';

import type { FooterConfig } from './footer-config';
import { FooterNavItem } from './footer-nav-item';
import { ToggledFooterSection } from './toggled-footer-section';

export const SmallScreenFooter: React.FC<{ config: FooterConfig }> = ({
  config,
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(0);

  return (
    <FooterSections data-qa="frame-footer-smallscreen-sections">
      {config.map(({ title, items }, sectionIndex) => (
        <ToggledFooterSection
          key={sectionIndex}
          isOpen={activeIndex === sectionIndex}
          onTitleClick={() =>
            setActiveIndex((current) =>
              current === sectionIndex ? null : sectionIndex
            )
          }
          title={title}
        >
          {items.map((item, itemIndex) => (
            <FooterNavItem key={itemIndex} {...item} />
          ))}
        </ToggledFooterSection>
      ))}
    </FooterSections>
  );
};

const FooterSections = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  margin-top: ${spaceS};
  width: 100%;

  @media ${mediaWideNavless} {
    display: none;
  }
`;
