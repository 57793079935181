import {
  type NWTEvent,
  type PageViewTrackingEvent,
  useTracking,
} from '@xing-com/crate-communication-tracking';

export type TrackParams = {
  page?: string;
  PropTrackAction?: string;
  hashedUserId?: string;
  signupChannel?: string;
  EventRegistrationUnconfirmed?: number;
  EventRegistrationFirstLogin?: number;
  EventLogout?: number;
};

export type NwtTrackParams = {
  page: string;
  event: string;
  context?: string;
};

export type AdobeTrackParams = {
  PropTrackAction: string;
};

type LoginTrackingType = {
  trackPageView: (params: TrackParams) => void;
  trackNwtInteraction: (params: NwtTrackParams) => void;
  trackAdobeInteraction: (params: AdobeTrackParams) => void;
};

const useLoginTracking = (): LoginTrackingType => {
  const { track: trackAdobePageView } = useTracking<PageViewTrackingEvent>();
  const { track: trackNwt } = useTracking<NWTEvent>();
  const { track: trackAdobe } = useTracking<{
    PropTrackAction: string;
  }>();

  const trackPageView = ({
    page,
    PropTrackAction,
    hashedUserId,
    signupChannel,
    EventRegistrationUnconfirmed,
    EventRegistrationFirstLogin,
    EventLogout,
  }: TrackParams): void => {
    const contextProps: Record<string, unknown> = {};
    const additionalNWTProps: Record<string, unknown> = {};

    if (PropTrackAction) {
      contextProps.PropTrackAction = PropTrackAction;
    }
    if (EventLogout) {
      contextProps.EventLogout = EventLogout;
    }
    if (EventRegistrationUnconfirmed) {
      contextProps.EventRegistrationUnconfirmed = EventRegistrationUnconfirmed;
    }
    if (hashedUserId) {
      contextProps.PropHashedUserId = hashedUserId;
    }
    if (signupChannel) {
      contextProps.PropRegistrationBackendChannel = signupChannel;
    }

    if (EventRegistrationFirstLogin) {
      contextProps.EventRegistrationFirstLogin = EventRegistrationFirstLogin;
    }

    trackAdobePageView({
      type: 'pageview',
      channel: 'wbm/Welcome',
      page: `wbm/Welcome/${page}`,
      ...contextProps,
    });

    trackNwt({
      type: 'nwt',
      event: 'viewed_screen',
      eventSchema: 'basic',
      sentBy: 'welcome',
      page: `welcome/${page}`,
      entryPoint: signupChannel ? `signup_channel_${signupChannel}` : undefined,
      ...additionalNWTProps,
    });
  };

  const trackNwtInteraction = ({
    page,
    event,
    context,
  }: NwtTrackParams): void => {
    trackNwt({
      type: 'nwt',
      event,
      context,
      eventSchema: 'basic',
      sentBy: 'welcome',
      page: `welcome/${page}`,
    });
  };
  const trackAdobeInteraction = ({
    PropTrackAction,
  }: AdobeTrackParams): void => {
    trackAdobe({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction,
    });
  };

  return {
    trackPageView,
    trackNwtInteraction,
    trackAdobeInteraction,
  };
};

export { useLoginTracking };
