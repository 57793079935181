import type * as React from 'react';
import styled from 'styled-components';

import { Link } from '@xing-com/link';
import { xdlColorText } from '@xing-com/tokens';
import { addParams } from '@xing-com/xing-tracking';

const tracking = {
  sc_o: 'navigation_footer',
};

export const FooterNavItem: React.FC<{
  title: string;
  href: string;
  skipTracking?: boolean;
}> = ({ title, href, skipTracking }) => {
  const link = addParams(href, skipTracking ? {} : tracking);

  return (
    <FooterNavItemLi>
      <Link to={link}>{title}</Link>
    </FooterNavItemLi>
  );
};

const FooterNavItemLi = styled.li`
  line-height: 2.5em;

  & a {
    color: ${xdlColorText};
  }
`;
