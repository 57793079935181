import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconCross, IconWarning, IconTick, IconInfo } from '@xing-com/icons';

import * as Styled from './status-banner.styles';
import type {
  StatusBannerProps,
  StatusBannerVariant,
} from './status-banner.types';
type StatusBannerPrimitiveProps = Omit<StatusBannerProps, 'onExited' | 'show'>;

const getLeadIcon = (
  variant: StatusBannerVariant
): ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) => {
  switch (variant) {
    case 'success':
      return IconTick;
    case 'info':
      return IconInfo;
    default:
      return IconWarning;
  }
};

export const StatusBannerPrimitive = React.forwardRef<
  HTMLDivElement,
  StatusBannerPrimitiveProps
>(
  (
    {
      children,
      closeButtonProps,
      display = 'fixed',
      handleOnClose,
      showCloseButton = true,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const LeadIcon = getLeadIcon(variant);

    return (
      <Styled.StatusBanner
        $display={display}
        $variant={variant}
        aria-live={variant === 'error' ? 'assertive' : 'polite'}
        data-xds="StatusBanner"
        ref={forwardedRef}
        role="alert"
        {...props}
      >
        <Styled.Content role="presentation">
          <Styled.Icon $variant={variant} aria-hidden="true">
            <LeadIcon />
          </Styled.Icon>
          <Styled.Text $variant={variant} aria-atomic="true" size="small">
            {children}
          </Styled.Text>
        </Styled.Content>

        {handleOnClose && showCloseButton ? (
          <Styled.Close
            $variant={variant}
            aria-label={intl.formatMessage({
              id: 'XDS_CLOSE_BUTTON_A11Y',
              defaultMessage: 'Close',
            })}
            data-qa="close"
            data-testid="close"
            icon={IconCross}
            onClick={handleOnClose}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              if (e.key === 'Escape') {
                handleOnClose?.();
              }
            }}
            size="medium"
            {...closeButtonProps}
          />
        ) : null}
      </Styled.StatusBanner>
    );
  }
);

StatusBannerPrimitive.displayName = 'StatusBanner (Primitive)';
