/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconNotificationFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconNotificationFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 22a4 4 0 0 1-3.121-1.498h6.243A4 4 0 0 1 12 22m1.374-17.865a8 8 0 0 1 .425.098 7 7 0 0 1 .4.12l.069.023.067.023.214.08.076.03.076.031.036.015-.019-.008.033.014.05.022.064.028.116.053.1.048a7 7 0 0 1 .859.501q.068.047.135.095l.05.036a7 7 0 0 1 .96.845 7 7 0 0 1 1.048 1.434l.074.138.036.07.038.075.036.074a7 7 0 0 1 .322.794q.091.275.162.56l.007.029a7 7 0 0 1 .09.435l.003.02A7 7 0 0 1 19 10.951V17h2v2H3v-2h2v-6a7 7 0 0 1 .1-1.182l.003-.02a7 7 0 0 1 .505-1.657l.056-.122.039-.08a7 7 0 0 1 .435-.766l.084-.125.054-.078.072-.1.06-.081.076-.099a7 7 0 0 1 1.381-1.339l.083-.06.093-.065.092-.062a7 7 0 0 1 .798-.457l.089-.043.115-.053.122-.053a7 7 0 0 1 .636-.235l.072-.023a7 7 0 0 1 .681-.17l.162-.029.051-.008L11 4.07V2h2l.001 2.071.192.03-.011-.002L13 4.071q.189.027.373.064"
    />
  </svg>
);
export default IconNotificationFilled;
