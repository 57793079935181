/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconProBusinessFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconProBusinessFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1m0 2.25a8.75 8.75 0 1 0 6.695 3.115l.022-.023-1.061-1.06-5.268 5.268a1.5 1.5 0 1 0 1.062 1.062l1.317-1.318a3.25 3.25 0 1 1-2.951-1.538L12 8.75v-1.5a4.75 4.75 0 1 0 3.847 1.963l1.782-1.783a7.25 7.25 0 1 1-5.868-2.676L12 4.75z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconProBusinessFilled;
