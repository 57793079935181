/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconHomeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconHomeFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2.089 3 9.998v12h7v-5h4v5h7v-12z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHomeFilled;
