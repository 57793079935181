/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconContacts"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.217 14c1.836 0 3.333.933 4.343 2.193l.044.056q.076-.086.15-.157c.721-.718 1.3-1.087 2.137-1.087h1.385C20.654 15.005 22 17.86 22 20h-2c0-.366-.199-1.217-.501-1.858-.36-.76-.778-1.137-1.223-1.137h-1.385c-.142 0-.36.139-.728.505q-.195.193-.508.632c.203.567.32 1.14.341 1.68L16 20h-2c0-.46-.344-1.515-.88-2.321C12.404 16.604 11.451 16 10.216 16H7.783c-1.235 0-2.188.604-2.902 1.679-.498.749-.83 1.711-.876 2.214L4 20H2c0-2.584 2.27-6 5.783-6zM17.5 9c.69 0 1.315.28 1.768.732.452.453.732 1.078.732 1.768s-.28 1.315-.732 1.768A2.5 2.5 0 0 1 17.5 14c-.69 0-1.315-.28-1.768-.732A2.5 2.5 0 0 1 15 11.5c0-.69.28-1.315.732-1.768A2.5 2.5 0 0 1 17.5 9M9 3c1.38 0 2.63.56 3.536 1.464A4.98 4.98 0 0 1 14 8c0 1.38-.56 2.63-1.464 3.536A4.98 4.98 0 0 1 9 13c-1.38 0-2.63-.56-3.536-1.464A4.98 4.98 0 0 1 4 8c0-1.38.56-2.63 1.464-3.536A4.98 4.98 0 0 1 9 3m8.5 7.5c-.27 0-.52.106-.707.293a1 1 0 0 0-.293.707c0 .27.106.52.293.707a1 1 0 0 0 .707.293c.27 0 .52-.106.707-.293a1 1 0 0 0 .293-.707c0-.27-.106-.52-.293-.707a1 1 0 0 0-.707-.293M9 5c-.808 0-1.561.318-2.121.879A2.98 2.98 0 0 0 6 8c0 .808.318 1.561.879 2.121S8.192 11 9 11s1.561-.318 2.121-.879S12 8.808 12 8s-.318-1.561-.879-2.121A2.98 2.98 0 0 0 9 5"
    />
  </svg>
);
export default IconContacts;
