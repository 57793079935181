/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconNewChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconNewChat"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3v2H5v14h14v-8h2v10H3V3zm6.02-1.2 4.244 4.243-9.966 9.965-4.177-.066-.065-4.177zm0 2.828-7.951 7.953.022 1.392 1.392.022 7.952-7.952z"
    />
  </svg>
);
export default IconNewChat;
