/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconProBusiness = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconProBusiness"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3v2a7 7 0 1 0 5.607 2.808l-1.435 1.435A5 5 0 1 1 12 7v2a3 3 0 1 0 2.708 1.707l-2 2a1 1 0 1 1-1.414-1.414l6.363-6.364.707.707A9 9 0 1 1 12 3"
    />
  </svg>
);
export default IconProBusiness;
