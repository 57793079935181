/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconContactFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconContactFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.217 15c3.432 0 5.68 3.262 5.78 5.823L19 21H5c0-2.526 2.169-5.845 5.546-5.995l.237-.005zM12 3a5 5 0 1 0 0 10 5 5 0 0 0 0-10"
    />
  </svg>
);
export default IconContactFilled;
