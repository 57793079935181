/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Portal } from '@xing-com/portal';

import { StatusBannerPrimitive } from './status-banner.primitive';
import {
  statusBannerDuration,
  statusBannerTransitionName,
} from './status-banner.styles';
import type { StatusBannerProps } from './status-banner.types';

export const StatusBanner = React.forwardRef<HTMLDivElement, StatusBannerProps>(
  (
    {
      children,
      className,
      closeButtonProps,
      display = 'fixed',
      handleOnClose,
      onExited,
      show = true,
      showCloseButton = true,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const bannerRef = React.useRef<HTMLDivElement>(null);

    const combinedRef = React.useMemo(() => {
      if (forwardedRef) {
        return typeof forwardedRef === 'function'
          ? { current: null, set: forwardedRef }
          : forwardedRef;
      }
      return bannerRef;
    }, [forwardedRef]);

    const handleKeyDown = React.useCallback(
      (e: KeyboardEvent): void => {
        if (e.key === 'Escape' && handleOnClose) {
          handleOnClose();
        }
      },
      [handleOnClose]
    );

    const timeout = display === 'fixed' ? parseInt(statusBannerDuration) : 0;

    // Focus management when banner appears
    React.useEffect(() => {
      if (show && display === 'fixed') {
        const previousFocus = document.activeElement as HTMLElement;
        combinedRef.current?.focus();

        return () => {
          previousFocus?.focus();
        };
      }

      return undefined;
    }, [show, display, combinedRef]);

    // Handle ESC to close the banner
    React.useEffect(() => {
      if (show) {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }
      return undefined;
    }, [show, handleKeyDown]);

    const statusBanner = (
      <StatusBannerPrimitive
        closeButtonProps={closeButtonProps}
        display={display}
        handleOnClose={handleOnClose}
        ref={combinedRef}
        showCloseButton={showCloseButton}
        tabIndex={-1}
        variant={variant}
        {...props}
      >
        {children}
      </StatusBannerPrimitive>
    );

    const StatusBannerTransition = (
      <CSSTransition
        appear={show}
        className={className}
        classNames={statusBannerTransitionName}
        in={show}
        key="statusbanner"
        mountOnEnter
        onExited={onExited}
        timeout={timeout}
        unmountOnExit
      >
        {statusBanner}
      </CSSTransition>
    );

    if (display === 'fixed') {
      return <Portal>{StatusBannerTransition}</Portal>;
    }

    return StatusBannerTransition;
  }
);

StatusBanner.displayName = 'StatusBanner';
