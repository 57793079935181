/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconPremiumFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconPremiumFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m21.953 10-9.957 11.53-.003-.004L16.005 10zm-8.059 0-3.406 9.784L2.04 10zm3.547-7 4.501 5H10.091l1.741-5zm-9.46 5h-5.93l4.497-5h3.174z"
    />
  </svg>
);
export default IconPremiumFilled;
