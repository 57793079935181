/**
 * Returns either or depending on the passed boolean.
 */
export const eitherOr = (bool?: unknown) => {
  return <V>([either, or]: [V, V]): V => (bool ? either : or);
};

/**
 * Decides which parameter to choose depending on:
 *
 * - `count` - notifications count, could be provided or undefined (if no badge is expected)
 * - `val` - tracking parameter, an array [tracking with badge, without badge]
 *
 * Difference from `eitherOr`: adds to the tracking parameter (with badge) the number of notifications.
 */
export const eitherOrWithCount = (count: number | null | undefined) => {
  return ([either, or]: [string, string]): string | `${string}_${number}` => {
    return count ? `${either}_${count}` : or;
  };
};

/**
 * Add query string params to a URL/path.
 *
 * Any params added will overwrite params that were in the input URL/path.
 *
 * @param to URL/path to add query string params to
 * @param values Set of key/values to add
 * @returns URL/path with the query string params added/overwritten
 */
export const addParams = (
  to: string,
  values: Record<string, string>
): string => {
  return addParamsWithConditions(to, values, {});
};

/**
 * Add query string params to a URL/path with conditions.
 *
 * Same as `addParamsWithConditions` but with a stricter type signature.
 * All added params must have a condition function. But the functions can take
 * more than tuples of strings.
 *
 * Any params added will overwrite params that were in the input URL/path.
 *
 * All values get passed through the condition functions to calculate their string values.
 *
 * @param to URL/path to add query string params to
 * @param values Set of key/values to add, where value is the input for the respective condition function.
 * @param condition Record with functions like `eitherOr`/`eitherOrWithCount` for each param.
 * @returns URL/path with the query string params added/overwritten
 */
export const addParamsWithStrictConditions = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Conditions extends Record<string, (...args: any) => string>,
>(
  to: string,
  values: { [K in keyof Conditions]: Parameters<Conditions[K]>[0] },
  conditions: Conditions
): string => {
  return addParamsWithConditions(to, values, conditions);
};

/**
 * Add query string params to a URL/path with conditions.
 *
 * Any params added will overwrite params that were in the input URL/path.
 *
 * If a value is a pair, the condition function is used to decide which part of the pair to use.
 *
 * Params that are not pairs, just strings will be used as-is.
 *
 * @param to URL/path to add query string params to
 * @param values Set of key/values to add, where value can be a string or a pair
 * @param condition Record with functions like `eitherOr`/`eitherOrWithCount` for each param that is a pair.
 * @throws Error if a condition is not provided for a value entry that is a pair
 * @returns URL/path with the query string params added/overwritten
 */
export const addParamsWithConditions = (
  to: string,
  values: Record<string, string | [string, string]>,
  conditions: Record<string, (value: [string, string]) => string>
): string => {
  const [path, params] = to.split('?', 2);
  const parsedParams = new URLSearchParams(params);

  for (const [key, value] of Object.entries(values)) {
    if (typeof value === 'string') {
      parsedParams.set(key, value);
    } else {
      const condition = conditions[key];
      if (!condition) {
        throw new Error(`No condition provided for ${key}`);
      }
      parsedParams.set(key, condition(value));
    }
  }

  const queryString = parsedParams.toString().replaceAll('+', '%20');

  return queryString.length ? `${path}?${queryString}` : path;
};
