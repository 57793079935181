import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { useLoginState } from '@xing-com/hub';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { Logo } from '@xing-com/platform-layout-logo';
import {
  scale090,
  scale150,
  scale200,
  spaceL,
  spaceM,
  spaceXS,
  spaceXXL,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import { LegalFooterNavItems } from './legal-footer-nav-items';

const FooterBranding: FC<{ homeLink: string }> = ({ homeLink }) => {
  const { formatMessage } = useIntl();
  return (
    <StyledBranding data-qa="frame-footer-legal-branding">
      <StyledLogo dataQa="logo" homeLink={homeLink} variant="duo" />
      <span>
        <FormattedMessage
          id="MALT_FOOTER_COPYRIGHT_API"
          values={{
            siteoperator: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_NEWWORKSE',
            }),
          }}
        />
      </span>
    </StyledBranding>
  );
};

const StyledFooterBox = styled.div`
  color: ${xdlColorTextSecondary};

  @media ${mediaSmallOrTiny} {
    padding-inline: ${spaceL};
    margin-bottom: ${scale090};
    margin-top: ${spaceM};
  }

  @media ${mediaConfined} {
    margin-bottom: ${scale200};
    margin-top: ${scale150};
  }
`;

const StyledBranding = styled.div`
  @media ${mediaSmallOrTiny} {
    margin-bottom: ${spaceXXL};
  }

  @media ${mediaConfined} {
    display: flex;
    align-items: center;
  }
`;

const StyledFooterNavLinksList = styled.ul`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  list-style: none;
  column-gap: ${spaceXXL};
  margin: 0;
  padding: 0;

  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

const StyledFooterLanguage = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spaceL};

  @media ${mediaSmallOrTiny} {
    margin-right: ${spaceL};
    margin-top: ${spaceM};
  }
`;

const StyledLogo = styled(Logo)`
  flex: 0 0 64px;
  margin-bottom: ${spaceM};
  margin-right: ${spaceL};
  margin-top: ${spaceXS};
  padding-bottom: ${spaceXS};

  & svg {
    height: 29px;
    width: 64px;
  }
`;

const StyledFooterLanguageLabel = styled.p`
  margin: 0;
  padding-right: ${spaceM};
`;

export const LegalFooterWithBranding: React.FC<{
  homeLink: string;
}> = ({ homeLink }) => {
  const isLoggedIn = useLoginState() === 'LOGGED_IN';

  const languageLink = isLoggedIn
    ? '/settings/account/language?sc_o=navigation_footer'
    : '/language?sc_o=navigation_footer';

  return (
    <StyledFooterBox>
      <FooterBranding homeLink={homeLink} />
      <nav data-qa="frame-footer-legal-nav">
        <StyledFooterNavLinksList>
          <LegalFooterNavItems />
        </StyledFooterNavLinksList>

        <StyledFooterLanguage data-qa="frame-footer-legal-language">
          <StyledFooterLanguageLabel>
            <FormattedMessage id="FOOTER_LANGUAGES" />
          </StyledFooterLanguageLabel>
          <Button size="small" variant="tertiary" to={languageLink}>
            <FormattedMessage id="ACTIVE_LANGUAGE" />
          </Button>
        </StyledFooterLanguage>
      </nav>
    </StyledFooterBox>
  );
};
