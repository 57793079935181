import { useFetch, useLoginAppUrl } from '@xing-com/crate-xinglet';

import { getCSRFToken } from './get-csrf-token';

export { getCSRFToken };
export * as mockedResponses from './mocked-responses';
export { useWindowOptions } from './use-window-options';
export { useSignupLink } from './use-signup-link';
export { useTfaSubmit } from './use-tfa-submit';

export function useLogout(): () => Promise<void> {
  const fetch = useFetch();
  const host = useLoginAppUrl().replace(/\/$/, '');

  const execute = async (): Promise<void> => {
    await fetch(host + '/login/logout', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'x-csrf-token': getCSRFToken(),
      },
    });
  };

  return execute;
}

export const addPrefixUrl = (url: string, hostName: string): string => {
  if (hostName !== 'localhost') {
    return url.replace('https://', 'https://www.');
  }
  return url;
};

export const getMainHost = (hostName: string): string =>
  hostName === 'localhost'
    ? 'http://localhost:8080'
    : 'https://' + hostName.replace('login.', '');

export const getExtraParams = (urlParams: URLSearchParams): string => {
  const register_redirect_to = urlParams.get('register_redirect_to');
  const destUrl = urlParams.get('dest_url');
  const registerRedirectParam = register_redirect_to
    ? `&register_redirect_to=${register_redirect_to}`
    : '';
  const destUrlParam = destUrl ? `&dest_url=${destUrl}` : '';

  return registerRedirectParam + destUrlParam;
};
