import { type ElementType, type FC } from 'react';

import { useActiveItem, useNotifications } from '@xing-com/hub';
import {
  addParamsWithConditions,
  eitherOrWithCount,
} from '@xing-com/xing-tracking';

import {
  StyledIconWithBadge,
  StyledLink,
  StyledTitle,
} from './nav-item-header-logged-out.styles';

const keyToMembershipType = (key: string): string | undefined => {
  switch (key) {
    case 'premium':
    case 'premiumUpsell':
      return 'premium';
    case 'proJobs':
    case 'proJobsUpsell':
      return 'proJobs';
    case 'proBusiness':
    case 'proBusinessUpsell':
      return 'proBusiness';
    default:
      return undefined;
  }
};

type NavItemHeaderLoggedOutItemProps = {
  item: {
    key: string;
    icon: ElementType;
    activeIcon: ElementType;
    href: string;
    notificationKey?: string;
    title: string;
    activeItemValues?: string[];
    tracking?: Record<string, string | [string, string]>;
  };
};

export const NavItemHeaderLoggedOut: FC<NavItemHeaderLoggedOutItemProps> = ({
  item,
}) => {
  const activeItem = useActiveItem();
  const notifications = useNotifications();
  const href = item.href;

  const isActive =
    activeItem && item.activeItemValues
      ? item.activeItemValues.includes(activeItem)
      : activeItem === item.key;
  const notificationCount =
    notifications && notifications[item.notificationKey || ''];

  // When active, use regular icon as fallback if no activeIcon is specified
  const IconElement = isActive ? item.activeIcon || item.icon : item.icon;
  const trackingConditions = {
    sc_o_PropActionOrigin: eitherOrWithCount(notificationCount),
  };

  const to = item.tracking
    ? addParamsWithConditions(href, item.tracking, trackingConditions)
    : href;

  return (
    <>
      <StyledLink
        $isActive={isActive}
        $membership={keyToMembershipType(item.key)}
        data-qa={`frame-vnav-${item.key}`}
        to={to}
      >
        <StyledIconWithBadge
          $membership={keyToMembershipType(item.key)}
          icon={IconElement}
          title={item.title}
          count={notificationCount}
        />
        <StyledTitle>{item.title}</StyledTitle>
      </StyledLink>
    </>
  );
};
