import type * as React from 'react';
import styled from 'styled-components';

import { IconArrowDown } from '@xing-com/icons';
import {
  mediaConfinedNavlessOnly,
  mediaSmallOrTiny,
  rowPaddingM,
  rowPaddingS,
} from '@xing-com/layout-tokens';
import {
  scale160,
  scale090,
  spaceL,
  xdlColorBackgroundSecondary,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const ToggledFooterSection: React.FC<{
  children: React.ReactNode;
  isOpen: boolean;
  onTitleClick: () => void;
  title: string;
}> = ({ children, isOpen, onTitleClick, title }) => {
  return (
    <LinkGroup>
      <Title onClick={onTitleClick}>
        <span>{title}</span>
        <Icon $isOpen={isOpen} width={24} height={24} />
      </Title>
      {isOpen && <FooterNav>{children}</FooterNav>}
    </LinkGroup>
  );
};

const Icon = styled(IconArrowDown)<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 250ms cubic-bezier(0.2, 0.2, 0.2, 1);
  will-change: transform;
`;

const LinkGroup = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1 0 0;
`;

const Title = styled(Headline).attrs({
  as: 'p',
  size: 'small',
})`
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 6px;
  padding-top: 7.5px;
  position: relative;
  user-select: none;

  @media ${mediaSmallOrTiny} {
    padding-inline: ${spaceL};
  }

  @media ${mediaConfinedNavlessOnly} {
    padding-inline: ${scale090};
  }
`;

const FooterNav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${xdlColorBackgroundSecondary};

  @media ${mediaSmallOrTiny} {
    padding-inline: ${spaceL};
  }

  @media ${mediaConfinedNavlessOnly} {
    padding-inline: ${scale090};
  }

  & li {
    height: ${scale160};
    line-height: ${scale160};

    @media ${mediaSmallOrTiny} {
      padding-inline: ${rowPaddingS};
    }

    @media ${mediaConfinedNavlessOnly} {
      padding-inline: ${rowPaddingM};
    }
  }
`;
