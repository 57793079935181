/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconFilter"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 16v4H6v-1H3v-2h3v-1zm13 1v2H10v-2zm-5-7v4h-2v-1H3v-2h11v-1zm5 1v2h-3v-2zm-9-7v4h-2V7H3V5h7V4zm9 1v2h-7V5z"
    />
  </svg>
);
export default IconFilter;
