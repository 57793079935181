/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconContact = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconContact"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.217 15C16.729 15 19 18.416 19 21h-2c0-.46-.344-1.515-.88-2.321C15.404 17.604 14.451 17 13.216 17h-2.434c-1.235 0-2.188.604-2.902 1.679C7.344 19.485 7 20.539 7 21H5c0-2.584 2.27-6 5.783-6zM12 3a5 5 0 1 1 0 10 5 5 0 0 1 0-10m0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6"
    />
  </svg>
);
export default IconContact;
