import type * as React from 'react';
import styled from 'styled-components';

import type { FooterConfig } from './footer-config';
import { FooterNavItem } from './footer-nav-item';
import { FooterSection, FooterSectionPlaceholder } from './footer-section';

const FOOTER_SECTION_TOTAL_COUNT = 5;

export const BigScreenFooter: React.FC<{ config: FooterConfig }> = ({
  config,
}) => {
  const placeholderCount = FOOTER_SECTION_TOTAL_COUNT - config.length;

  return (
    <FooterSections data-qa="frame-footer-sections">
      {config.map(({ title, items }, sectionIndex) => (
        <FooterSection key={sectionIndex} title={title}>
          {items.map((item, itemIndex) => (
            <FooterNavItem key={itemIndex} {...item} />
          ))}
        </FooterSection>
      ))}
      {Array(placeholderCount)
        .fill('')
        .map((_, i) => (
          <FooterSectionPlaceholder key={i} />
        ))}
    </FooterSections>
  );
};

const FooterSections = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;
