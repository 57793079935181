/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconCompanies = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconCompanies"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21 2v20H3V9h8V2zm-2 2h-6v16h2v-2h2v2h2zm-8 7H5v9h2v-2h2v2h2zm-2 2v2H7v-2zm8-3v2h-2v-2zm0-4v2h-2V6z"
    />
  </svg>
);
export default IconCompanies;
